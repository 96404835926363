import { Profile } from '@agdir/domain';
import { ButtonComponent } from '@agdir/ui/button';
import { SpinnerComponent } from '@agdir/ui/loaders';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Component, inject, input, OnChanges, signal, SimpleChanges } from '@angular/core';
import { NzAvatarComponent } from 'ng-zorro-antd/avatar';
import { NzBadgeComponent } from 'ng-zorro-antd/badge';
import { filter, firstValueFrom } from 'rxjs';
import { CameraUploadComponent } from './camera-upload/camera-upload.component';
import { FileUploadComponent } from './file-upload.component';
import { FileService } from './file.service';

/**
 * TODO: Created in a rush for NLR demo. needs a cleanup and maybe refactoring
 */
@Component({
	standalone: true,
	selector: 'agdir-profile-photo-component',

	template: `
		<div
			class="h-full w-full rounded-full bg-gray-300 outline outline-1 outline-offset-1 outline-gray-200 flex items-center justify-center relative "
			[class.cursor-pointer]="allowUpload()"
		>
			@if (filesInProgress()) {
				<agdir-spinner />
			} @else if (userPhotoUrl()) {
				<div
					class="flex-shrink-0 w-[inherit] h-[inherit] rounded-full bg-cover bg-center"
					[style.backgroundImage]="'url(' + userPhotoUrl() + ')'"
					(click)="allowUpload() && fu.open()"
				></div>
			} @else {
				<nz-avatar [nzIcon]="'user'" (click)="allowUpload() && fu.open()" />
			}
			@if (allowUpload()) {
				<agdir-button icon="plus" size="large" color="custom" stroke="white" />
			}
		</div>
		<agdir-file-upload #fu (fileAdded)="upload($event)" [doNotRender]="true" />
	`,
	styles: [
		`
			:host {
				@apply flex items-center justify-center;
			}
		`,
		`
			agdir-button {
				stroke: red;
				@apply absolute bottom-0 right-0 text-gray-50 rounded-full  bg-gray-600;
			}
		`,
		`
			agdir-button ::ng-deep button {
				@apply p-0.5 rounded-full;
			}
		`,
	],
	imports: [CameraUploadComponent, FileUploadComponent, AsyncPipe, SpinnerComponent, NzAvatarComponent, NzBadgeComponent, ButtonComponent],
})
export class ProfilePhotoComponent implements OnChanges {
	customer = input<Profile>();
	allowUpload = input(false);
	userPhotoUrl = signal<string | null>(null);
	filesInProgress = signal(true);
	private fileService = inject(FileService);
	private cd = inject(ChangeDetectorRef);

	async ngOnChanges(changes: SimpleChanges) {
		const customer: Profile | undefined = changes['customer']?.currentValue;
		if (customer) {
			const photo = await this.fileService.download(`profile/${customer.customerId}`);
			this.userPhotoUrl.set(photo);
			this.filesInProgress.set(false);
		}
	}

	async upload(file: File) {
		const uploadResult = await this.fileService.upload(file, 'profile', String(this.customer().customerId));
		this.filesInProgress.set(true);
		await firstValueFrom(uploadResult.done.pipe(filter((done) => !!done)));
		this.filesInProgress.set(false);
		this.userPhotoUrl.set(await this.fileService.download(uploadResult.path));
	}

	fileError() {
		this.userPhotoUrl.set(null);
		this.cd.detectChanges();
	}
}
