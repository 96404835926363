import { Pipe, PipeTransform } from '@angular/core';
import { AgdirFile } from '@agdir/fillagring/domain';

@Pipe({
	standalone: true,
	name: 'countAgdirFileItems',
})
export class CountAgdirFileItemsPipe implements PipeTransform {
	private recursiveTraverseRegistry = new Set();

	transform(file: AgdirFile): number {
		return this.count(file.children);
	}

	private count(files: AgdirFile[] = []): number {
		let count = 0;

		const traverse = (element: AgdirFile) => {
			if (!this.recursiveTraverseRegistry.has(element)) {
				this.recursiveTraverseRegistry.add(element);
				count++;
				element.children?.forEach(traverse);
			}
		};
		files.forEach(traverse);
		return count;
	}
}
