import { Pipe, PipeTransform } from '@angular/core';
import { AgdirFile } from '@agdir/fillagring/domain';

@Pipe({
	standalone: true,
	name: 'countAgdirFileSize',
})
export class CountAgdirFileSizePipe implements PipeTransform {
	transform(file: AgdirFile): number {
		return file.size + this.count(file.children);
	}

	count(files: AgdirFile[] = []): number {
		let count = 0;
		const traverse = (element: AgdirFile) => {
			count += element.size;
			element.children?.forEach(traverse);
		};
		files.forEach(traverse);
		return count;
	}
}
